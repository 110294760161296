import React, {useState} from "react";
import PropTypes from "prop-types";
import View from "components/preventive_maintenances/done/List.view";
import { usePagination } from "seed/gql";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import { generatePDF } from "components/utils/file";
import { formatFilters, formatSearchFilters, formatEnumFilters } from "components/utils/filters";

const PreventiveMaintenancesDone = ({ search, searchBy }) => {

    const pageSize = 30;
    const [pageNum, setPageNum] = useState(1);
    const [orderDate, setOrderDate] = useState("-");
    const [zoneFilters, setZoneFilters] = useState({
        "NORTH": true,
        "SOUTH": true,
        "CENTRE": true,
        "SHALLOWS": true,
        "CANADA": true
    });

    let filters = formatFilters([
        formatEnumFilters(zoneFilters, "zone"),
        searchBy == "Grua" ? formatSearchFilters(search, ["order.crane.series", "order.crane.model", "order.crane.number"])
            : searchBy == "Obra" ? formatSearchFilters(search, ["order.buildingName"])
                : searchBy == "Tecnico" ? formatSearchFilters(search, ["technician.firstName"])
                    : searchBy == "Folio" ? formatSearchFilters(search, ["id"]) : null
    ]);

    filters = "(" + filters + ") AND status<>ASSIGNED AND status<>CREATED AND status<>IN_PROGRESS";

    const qPreventiveMaintenances = usePagination(`
    {
        preventiveMaintenancePagination{
            totalPages
            preventiveMaintenances {
                zone
                status
                startDate
                finishDate
                onedriveUrl
                sizeUpload
                order{
                    buildingName
                    clientName
                    crane{
                        model
                        series
                        number
                    }
                }
                technician {
                    firstName
                    lastName
                }
                signature{
                    url
                }
                report{
                    residentName
                    signature{
                        url
                    }
                }
                maintenanceOperatorEvaluations{
                    points
                    criteria{
                        criteria
                    }
                }
                maintenancePreventiveMaintenanceEvidences{
                    value
                    category{
                        title
                        subtitle
                        typeQuestion
                    }
                }
            }
        }
    }`, 
    pageNum,
    pageSize,
    filters,
    { orderBy: orderDate + "finish_date" });

    if (qPreventiveMaintenances.loading) return <Loading />;
    if (qPreventiveMaintenances.error) return <Error />;

    const { totalPages, preventiveMaintenances } = qPreventiveMaintenances.data.preventiveMaintenancePagination;

    const onClickPage = (pageNum) =>
        setPageNum(pageNum);

    return <View 
            preventiveMaintenances={preventiveMaintenances} 
            setZoneFilters={setZoneFilters}
            generatePDF={generatePDF}
            setOrderDate={setOrderDate}
            onClickPage={onClickPage}
            zoneFilters={zoneFilters}
            totalPages={totalPages}
            orderDate={orderDate}
            pageNum={pageNum} />;
            
}

PreventiveMaintenancesDone.propTypes = {
    orderDate: PropTypes.string,
};

export default PreventiveMaintenancesDone;