import React from "react";
import PropTypes from "prop-types";

const Details = ({
    isVideo,
    activeTab,
    renderStars,
    setActiveTab, 
    filteredEvidences,
    preventiveMaintenance
}) => (
    <div className="card shadow-sm p-4 mb-4">
        <h2 className="card-title text-center mb-4">Reporte de Mantenimiento Preventivo</h2>
        <div className="mb-3">
            <h5 className="card-subtitle mb-2 text-muted">Información General</h5>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <strong>Zona:</strong> {preventiveMaintenance?.zone == "SHALLOWS" ? "Bajío" : preventiveMaintenance?.zone == "CENTRE" ? "Centro" : preventiveMaintenance?.zone == "NORTH" ? "Norte" : preventiveMaintenance?.zone == "SOUTH" ? "Sur" : "Canadá"}
                </div>
                <div className="col-md-6 mb-2">
                    <strong>Estado:</strong> {preventiveMaintenance?.status == "APPROVED" ? "Aprobado" : "Finalizado"}
                </div>
            </div>
            <div className="table-responsive mb-3">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Técnico</th>
                            <th>Residente</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{preventiveMaintenance?.technician?.firstName}{" "}{preventiveMaintenance?.technician?.lastName}</td>
                            <td>
                                {preventiveMaintenance?.report?.residentName}
                                {preventiveMaintenance?.report?.residentMail && (
                                    <span title="Correo registrado para recibir el reporte" style={{ marginLeft: "5px" }}>
                                        <i className="fas fa-envelope" style={{ color: "green" }}></i>
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {preventiveMaintenance?.signature?.url && (
                                    <img src={preventiveMaintenance?.signature?.url} alt="Firma del Técnico" className="img-fluid img-thumbnail" style={{ width: "100px" }} />
                                )}
                            </td>
                            <td>
                                {preventiveMaintenance.report?.signature?.url && (
                                    <img src={preventiveMaintenance.report.signature.url} alt="Firma del Residente" className="img-fluid img-thumbnail" style={{ width: "150px" }} />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="mb-5">
            <h5 className="card-subtitle mb-2 text-muted">Evaluación del Operador</h5>
            {preventiveMaintenance.maintenanceOperatorEvaluations?.map((evaluation, index) => (
                <div key={index} className="border p-3 mb-2 rounded bg-light">
                    <div><strong>Criterio:</strong> {evaluation?.criteria?.criteria}</div>
                    <div><strong>Puntos:</strong> {renderStars(evaluation?.points)}</div>
                </div>
            ))}
        </div>
        <div className="mb-3">
            <h5 className="card-subtitle mb-3 text-muted">Evidencias del Mantenimiento</h5>
            <div className="btn-group btn-group-toggle mb-4 d-flex justify-content-center" data-toggle="buttons">
                <button 
                    className={`btn ${activeTab === "REVIEW" ? "btn-primary border-primary text-primary bg-white" : "btn-outline-primary"}`}
                    onClick={() => setActiveTab("REVIEW")}
                >
                    Revisión
                </button>
                <button 
                    className={`btn ${activeTab === "POWER" ? "btn-primary border-primary text-primary bg-white" : "btn-outline-primary"}`}
                    onClick={() => setActiveTab("POWER")}
                >
                    Alimentación
                </button>
            </div>
            <div className="row">
                {filteredEvidences.map((evidence, index) => (
                    <div key={index} className="col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-title">{evidence?.category?.title}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{evidence?.category?.subtitle}</h6>
                                <p className="card-text"><strong>Comentarios:</strong> {evidence?.value}</p>
                                <div className="card-img-bottom">
                                    <strong>Evidencia:</strong><br />
                                    {evidence.file?.url && (
                                        isVideo(evidence?.file?.url) ? (
                                            <video controls className="img-fluid img-thumbnail">
                                                <source src={evidence?.file?.url} type={`video/${evidence?.file?.url.split('.').pop().toLowerCase()}`} />
                                                Tu navegador no soporta el elemento video.
                                            </video>
                                        ) : (
                                            <img src={evidence.file.url} alt="Evidencia" className="img-fluid img-thumbnail" />
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

Details.propTypes = {
    preventiveMaintenance: PropTypes.object.isRequired,
    renderStars: PropTypes.func.isRequired
};

export default Details;
