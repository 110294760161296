/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const ACTION_LOG = `
{
  actionLog {
    id
    modelId
    modelName
    action
    value
  }
}
`;

export const SET_ACTION_LOG = `
mutation Set(
  $id: Int!,
  $modelId: Int,
  $modelName: String,
  $action: String,
  $value: GenericScalar,
)
{
  setActionLog(
    id: $id,
    modelId: $modelId,
    modelName: $modelName,
    action: $action,
    value: $value,
  ) {
    actionLog {
      id
      modelId
      modelName
      action
      value
    }
  }
}
`;

export const SAVE_ACTION_LOG = `
mutation Save(
  $modelId: Int!,
  $modelName: String!,
  $action: String!,
  $value: GenericScalar!,
)
{
  saveActionLog(
    modelId: $modelId,
    modelName: $modelName,
    action: $action,
    value: $value,
  ) {
    actionLog {
      id
    }
  }
}
`;

export const DELETE_ACTION_LOG = `
mutation Delete($id: Int!)
{
  deleteActionLog(id: $id)
  {
    id
  }
}
`;

export const APP_INFO = `
{
  appInfo {
    id
    version
  }
}
`;

export const SET_APP_INFO = `
mutation Set(
  $id: Int!,
  $version: String,
)
{
  setAppInfo(
    id: $id,
    version: $version,
  ) {
    appInfo {
      id
      version
    }
  }
}
`;

export const SAVE_APP_INFO = `
mutation Save(
  $version: String!,
)
{
  saveAppInfo(
    version: $version,
  ) {
    appInfo {
      id
    }
  }
}
`;

export const DELETE_APP_INFO = `
mutation Delete($id: Int!)
{
  deleteAppInfo(id: $id)
  {
    id
  }
}
`;

export const ARRIS_BINNACLE = `
{
  arrisBinnacle {
    id
    date
    eatWorkHours
    hoursWorked
    eatHours
    failuresHours
    status
    operator {
      id
    }
    tokenId {
      id
    }
  }
}
`;

export const SET_ARRIS_BINNACLE = `
mutation Set(
  $id: Int!,
  $date: String,
  $eatWorkHours: String,
  $hoursWorked: Int,
  $eatHours: Int,
  $failuresHours: Int,
  $status: String,
  $operator: Int,
  $tokenId: Int,
)
{
  setArrisBinnacle(
    id: $id,
    date: $date,
    eatWorkHours: $eatWorkHours,
    hoursWorked: $hoursWorked,
    eatHours: $eatHours,
    failuresHours: $failuresHours,
    status: $status,
    operator: $operator,
    tokenId: $tokenId,
  ) {
    arrisBinnacle {
      id
      date
      eatWorkHours
      hoursWorked
      eatHours
      failuresHours
      status
      operator {
        id
      }
      tokenId {
        id
      }
    }
  }
}
`;

export const SAVE_ARRIS_BINNACLE = `
mutation Save(
  $date: String!,
  $eatWorkHours: String!,
  $hoursWorked: Int!,
  $eatHours: Int!,
  $failuresHours: Int,
  $status: String!,
  $operator: Int!,
  $tokenId: Int,
)
{
  saveArrisBinnacle(
    date: $date,
    eatWorkHours: $eatWorkHours,
    hoursWorked: $hoursWorked,
    eatHours: $eatHours,
    failuresHours: $failuresHours,
    status: $status,
    operator: $operator,
    tokenId: $tokenId,
  ) {
    arrisBinnacle {
      id
    }
  }
}
`;

export const DELETE_ARRIS_BINNACLE = `
mutation Delete($id: Int!)
{
  deleteArrisBinnacle(id: $id)
  {
    id
  }
}
`;

export const ARRIS_CRANE_STATUS = `
{
  arrisCraneStatus {
    id
    type
    name
    status
    arrisForm {
      id
    }
  }
}
`;

export const SET_ARRIS_CRANE_STATUS = `
mutation Set(
  $id: Int!,
  $type: String,
  $name: String,
  $status: String,
  $arrisForm: Int,
)
{
  setArrisCraneStatus(
    id: $id,
    type: $type,
    name: $name,
    status: $status,
    arrisForm: $arrisForm,
  ) {
    arrisCraneStatus {
      id
      type
      name
      status
      arrisForm {
        id
      }
    }
  }
}
`;

export const SAVE_ARRIS_CRANE_STATUS = `
mutation Save(
  $type: String!,
  $name: String!,
  $status: String!,
  $arrisForm: Int!,
)
{
  saveArrisCraneStatus(
    type: $type,
    name: $name,
    status: $status,
    arrisForm: $arrisForm,
  ) {
    arrisCraneStatus {
      id
    }
  }
}
`;

export const DELETE_ARRIS_CRANE_STATUS = `
mutation Delete($id: Int!)
{
  deleteArrisCraneStatus(id: $id)
  {
    id
  }
}
`;

export const ARRIS_FAILURE = `
{
  arrisFailure {
    id
    description
    solution
    status
    solvedReport
    reportedSolved {
      id
    }
    arrisForm {
      id
    }
  }
}
`;

export const SET_ARRIS_FAILURE = `
mutation Set(
  $id: Int!,
  $description: String,
  $solution: String,
  $status: String,
  $solvedReport: Boolean,
  $reportedSolved: Int,
  $arrisForm: Int,
)
{
  setArrisFailure(
    id: $id,
    description: $description,
    solution: $solution,
    status: $status,
    reportedSolved: $reportedSolved,
    arrisForm: $arrisForm,
    solvedReport: $solvedReport,
  ) {
    arrisFailure {
      id
      description
      solution
      status
      solvedReport
      reportedSolved {
        id
      }
      arrisForm {
        id
      }
    }
  }
}
`;

export const SAVE_ARRIS_FAILURE = `
mutation Save(
  $description: String!,
  $solution: String!,
  $status: String!,
  $solvedReport: Boolean!,
  $reportedSolved: Int,
  $arrisForm: Int!,
)
{
  saveArrisFailure(
    description: $description,
    solution: $solution,
    status: $status,
    reportedSolved: $reportedSolved,
    arrisForm: $arrisForm,
    solvedReport: $solvedReport,
  ) {
    arrisFailure {
      id
    }
  }
}
`;

export const DELETE_ARRIS_FAILURE = `
mutation Delete($id: Int!)
{
  deleteArrisFailure(id: $id)
  {
    id
  }
}
`;

export const ARRIS_FILE = `
{
  arrisFile {
    id
    category
    uploadedOnedrive
    file {
      id
      url
    }
    arrisForm {
      id
    }
  }
}
`;

export const SET_ARRIS_FILE = `
mutation Set(
  $id: Int!,
  $file: Int,
  $category: String,
  $uploadedOnedrive: Boolean,
  $arrisForm: Int,
)
{
  setArrisFile(
    id: $id,
    file: $file,
    category: $category,
    uploadedOnedrive: $uploadedOnedrive,
    arrisForm: $arrisForm,
  ) {
    arrisFile {
      id
      category
      uploadedOnedrive
      file {
        id
      }
      arrisForm {
        id
      }
    }
  }
}
`;

export const SAVE_ARRIS_FILE = `
mutation Save(
  $file: Int!,
  $category: String,
  $uploadedOnedrive: Boolean,
  $arrisForm: Int,
)
{
  saveArrisFile(
    file: $file,
    category: $category,
    uploadedOnedrive: $uploadedOnedrive,
    arrisForm: $arrisForm,
  ) {
    arrisFile {
      id
    }
  }
}
`;

export const DELETE_ARRIS_FILE = `
mutation Delete($id: Int!)
{
  deleteArrisFile(id: $id)
  {
    id
  }
}
`;

export const ARRIS_FORM = `
{
  arrisForm {
    id
    q1
    q2
    activity
    reasonMissing
    solutionMissing
    onedriveUrl
    onedriveStatus
    status
    date
    isElevator
    operator {
      id
    }
  }
}
`;

export const SET_ARRIS_FORM = `
mutation Set(
  $id: Int!,
  $q1: String,
  $q2: String,
  $activity: String,
  $reasonMissing: String,
  $solutionMissing: String,
  $onedriveUrl: String,
  $onedriveStatus: String,
  $status: String,
  $date: DateTime,
  $isElevator: Boolean,
  $operator: Int,
)
{
  setArrisForm(
    id: $id,
    q1: $q1,
    q2: $q2,
    activity: $activity,
    reasonMissing: $reasonMissing,
    solutionMissing: $solutionMissing,
    onedriveUrl: $onedriveUrl,
    onedriveStatus: $onedriveStatus,
    status: $status,
    operator: $operator,
    date: $date,
    isElevator: $isElevator,
  ) {
    arrisForm {
      id
      q1
      q2
      activity
      reasonMissing
      solutionMissing
      onedriveUrl
      onedriveStatus
      status
      date
      isElevator
      operator {
        id
      }
    }
  }
}
`;

export const SAVE_ARRIS_FORM = `
mutation Save(
  $q1: String!,
  $q2: String!,
  $activity: String!,
  $reasonMissing: String!,
  $solutionMissing: String!,
  $onedriveUrl: String,
  $onedriveStatus: String!,
  $status: String!,
  $date: DateTime!,
  $isElevator: Boolean,
  $operator: Int,
)
{
  saveArrisForm(
    q1: $q1,
    q2: $q2,
    activity: $activity,
    reasonMissing: $reasonMissing,
    solutionMissing: $solutionMissing,
    onedriveUrl: $onedriveUrl,
    onedriveStatus: $onedriveStatus,
    status: $status,
    operator: $operator,
    date: $date,
    isElevator: $isElevator,
  ) {
    arrisForm {
      id
    }
  }
}
`;

export const DELETE_ARRIS_FORM = `
mutation Delete($id: Int!)
{
  deleteArrisForm(id: $id)
  {
    id
  }
}
`;

export const ARRIS_OPERATOR = `
{
  arrisOperator {
    id
    active
    turn
    order {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_ARRIS_OPERATOR = `
mutation Set(
  $id: Int!,
  $active: Boolean,
  $turn: String,
  $order: Int,
  $user: Int,
)
{
  setArrisOperator(
    id: $id,
    active: $active,
    turn: $turn,
    order: $order,
    user: $user,
  ) {
    arrisOperator {
      id
      active
      turn
      order {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_ARRIS_OPERATOR = `
mutation Save(
  $active: Boolean!,
  $turn: String!,
  $order: Int!,
  $user: Int,
)
{
  saveArrisOperator(
    active: $active,
    turn: $turn,
    order: $order,
    user: $user,
  ) {
    arrisOperator {
      id
    }
  }
}
`;

export const DELETE_ARRIS_OPERATOR = `
mutation Delete($id: Int!)
{
  deleteArrisOperator(id: $id)
  {
    id
  }
}
`;

export const BI_MAINTENANCE_KPI = `
{
  biMaintenanceKpi {
    id
    maintenanceId
    typeId
    name
    categoryId
    inputType
    numEvidences
    startDate
    endDate
    realTime
    userName
    userId
  }
}
`;

export const SET_BI_MAINTENANCE_KPI = `
mutation Set(
  $id: Int!,
  $maintenanceId: Int,
  $typeId: Int,
  $name: String,
  $categoryId: Int,
  $inputType: String,
  $numEvidences: Int,
  $startDate: DateTime,
  $endDate: DateTime,
  $realTime: Int,
  $userName: String,
  $userId: Int,
)
{
  setBiMaintenanceKpi(
    id: $id,
    maintenanceId: $maintenanceId,
    typeId: $typeId,
    name: $name,
    categoryId: $categoryId,
    inputType: $inputType,
    numEvidences: $numEvidences,
    startDate: $startDate,
    endDate: $endDate,
    realTime: $realTime,
    userName: $userName,
    userId: $userId,
  ) {
    biMaintenanceKpi {
      id
      maintenanceId
      typeId
      name
      categoryId
      inputType
      numEvidences
      startDate
      endDate
      realTime
      userName
      userId
    }
  }
}
`;

export const SAVE_BI_MAINTENANCE_KPI = `
mutation Save(
  $maintenanceId: Int,
  $typeId: Int!,
  $name: String,
  $categoryId: Int!,
  $inputType: String,
  $numEvidences: Int!,
  $startDate: DateTime,
  $endDate: DateTime,
  $realTime: Int!,
  $userName: String,
  $userId: Int,
)
{
  saveBiMaintenanceKpi(
    maintenanceId: $maintenanceId,
    typeId: $typeId,
    name: $name,
    categoryId: $categoryId,
    inputType: $inputType,
    numEvidences: $numEvidences,
    startDate: $startDate,
    endDate: $endDate,
    realTime: $realTime,
    userName: $userName,
    userId: $userId,
  ) {
    biMaintenanceKpi {
      id
    }
  }
}
`;

export const DELETE_BI_MAINTENANCE_KPI = `
mutation Delete($id: Int!)
{
  deleteBiMaintenanceKpi(id: $id)
  {
    id
  }
}
`;

export const BINNACLE_TOKEN = `
{
  binnacleToken {
    id
    status
    usedDate
    link
    policies
    reviewerName
    signature {
      id
      url
    }
  }
}
`;

export const SET_BINNACLE_TOKEN = `
mutation Set(
  $id: Int!,
  $status: String,
  $usedDate: DateTime,
  $link: String,
  $policies: Boolean,
  $reviewerName: String,
  $signature: Int,
)
{
  setBinnacleToken(
    id: $id,
    status: $status,
    usedDate: $usedDate,
    link: $link,
    policies: $policies,
    reviewerName: $reviewerName,
    signature: $signature,
  ) {
    binnacleToken {
      id
      status
      usedDate
      link
      policies
      reviewerName
      signature {
        id
      }
    }
  }
}
`;

export const SAVE_BINNACLE_TOKEN = `
mutation Save(
  $status: String!,
  $usedDate: DateTime,
  $link: String,
  $policies: Boolean!,
  $reviewerName: String!,
  $signature: Int,
)
{
  saveBinnacleToken(
    status: $status,
    usedDate: $usedDate,
    link: $link,
    policies: $policies,
    reviewerName: $reviewerName,
    signature: $signature,
  ) {
    binnacleToken {
      id
    }
  }
}
`;

export const DELETE_BINNACLE_TOKEN = `
mutation Delete($id: Int!)
{
  deleteBinnacleToken(id: $id)
  {
    id
  }
}
`;

export const CONTENT = `
{
  content {
    id
    partId
    partName
    items
    quantity
    um
    manual
    suggestRef
    elevationNumber
    isSupervision
    isRecollection
    used
    commentDesviation
    shipping {
      id
    }
  }
}
`;

export const SET_CONTENT = `
mutation Set(
  $id: Int!,
  $partId: String,
  $partName: String,
  $items: String,
  $quantity: Int,
  $um: String,
  $manual: Boolean,
  $suggestRef: Int,
  $elevationNumber: Int,
  $isSupervision: Boolean,
  $isRecollection: Boolean,
  $used: Boolean,
  $commentDesviation: String,
  $shipping: Int,
)
{
  setContent(
    id: $id,
    partId: $partId,
    partName: $partName,
    items: $items,
    quantity: $quantity,
    um: $um,
    manual: $manual,
    shipping: $shipping,
    suggestRef: $suggestRef,
    elevationNumber: $elevationNumber,
    isSupervision: $isSupervision,
    isRecollection: $isRecollection,
    used: $used,
    commentDesviation: $commentDesviation,
  ) {
    content {
      id
      partId
      partName
      items
      quantity
      um
      manual
      suggestRef
      elevationNumber
      isSupervision
      isRecollection
      used
      commentDesviation
      shipping {
        id
      }
    }
  }
}
`;

export const SAVE_CONTENT = `
mutation Save(
  $partId: String!,
  $partName: String!,
  $items: String!,
  $quantity: Int!,
  $um: String!,
  $manual: Boolean!,
  $suggestRef: Int,
  $elevationNumber: Int!,
  $isSupervision: Boolean!,
  $isRecollection: Boolean!,
  $used: Boolean!,
  $commentDesviation: String!,
  $shipping: Int!,
)
{
  saveContent(
    partId: $partId,
    partName: $partName,
    items: $items,
    quantity: $quantity,
    um: $um,
    manual: $manual,
    shipping: $shipping,
    suggestRef: $suggestRef,
    elevationNumber: $elevationNumber,
    isSupervision: $isSupervision,
    isRecollection: $isRecollection,
    used: $used,
    commentDesviation: $commentDesviation,
  ) {
    content {
      id
    }
  }
}
`;

export const DELETE_CONTENT = `
mutation Delete($id: Int!)
{
  deleteContent(id: $id)
  {
    id
  }
}
`;

export const CRANE = `
{
  crane {
    id
    craneId
    model
    number
    series
    inUse
    owner
    status
    businessType
  }
}
`;

export const SET_CRANE = `
mutation Set(
  $id: Int!,
  $craneId: String,
  $model: String,
  $number: String,
  $series: String,
  $inUse: Boolean,
  $owner: String,
  $status: String,
  $businessType: String,
)
{
  setCrane(
    id: $id,
    craneId: $craneId,
    model: $model,
    number: $number,
    series: $series,
    inUse: $inUse,
    owner: $owner,
    status: $status,
    businessType: $businessType,
  ) {
    crane {
      id
      craneId
      model
      number
      series
      inUse
      owner
      status
      businessType
    }
  }
}
`;

export const SAVE_CRANE = `
mutation Save(
  $craneId: String!,
  $model: String!,
  $number: String!,
  $series: String!,
  $inUse: Boolean!,
  $owner: String!,
  $status: String!,
  $businessType: String!,
)
{
  saveCrane(
    craneId: $craneId,
    model: $model,
    number: $number,
    series: $series,
    inUse: $inUse,
    owner: $owner,
    status: $status,
    businessType: $businessType,
  ) {
    crane {
      id
    }
  }
}
`;

export const DELETE_CRANE = `
mutation Delete($id: Int!)
{
  deleteCrane(id: $id)
  {
    id
  }
}
`;

export const CRANE_COMMERCIAL = `
{
  craneCommercial {
    id
    craneId
    model
    number
    series
    inUse
    owner
    status
  }
}
`;

export const SET_CRANE_COMMERCIAL = `
mutation Set(
  $id: Int!,
  $craneId: String,
  $model: String,
  $number: String,
  $series: String,
  $inUse: Boolean,
  $owner: String,
  $status: String,
)
{
  setCraneCommercial(
    id: $id,
    craneId: $craneId,
    model: $model,
    number: $number,
    series: $series,
    inUse: $inUse,
    owner: $owner,
    status: $status,
  ) {
    craneCommercial {
      id
      craneId
      model
      number
      series
      inUse
      owner
      status
    }
  }
}
`;

export const SAVE_CRANE_COMMERCIAL = `
mutation Save(
  $craneId: String!,
  $model: String!,
  $number: String!,
  $series: String!,
  $inUse: Boolean!,
  $owner: String!,
  $status: String!,
)
{
  saveCraneCommercial(
    craneId: $craneId,
    model: $model,
    number: $number,
    series: $series,
    inUse: $inUse,
    owner: $owner,
    status: $status,
  ) {
    craneCommercial {
      id
    }
  }
}
`;

export const DELETE_CRANE_COMMERCIAL = `
mutation Delete($id: Int!)
{
  deleteCraneCommercial(id: $id)
  {
    id
  }
}
`;

export const CRM = `
{
  crm {
    id
    crmId
    clientName
    buildingName
    buildingState
    priceDate
    startMonth
    startYear
    months
    status
    craneModel
  }
}
`;

export const SET_CRM = `
mutation Set(
  $id: Int!,
  $crmId: String,
  $clientName: String,
  $buildingName: String,
  $buildingState: String,
  $priceDate: DateTime,
  $startMonth: Int,
  $startYear: Int,
  $months: Int,
  $status: String,
  $craneModel: String,
)
{
  setCrm(
    id: $id,
    crmId: $crmId,
    clientName: $clientName,
    buildingName: $buildingName,
    buildingState: $buildingState,
    priceDate: $priceDate,
    startMonth: $startMonth,
    startYear: $startYear,
    months: $months,
    status: $status,
    craneModel: $craneModel,
  ) {
    crm {
      id
      crmId
      clientName
      buildingName
      buildingState
      priceDate
      startMonth
      startYear
      months
      status
      craneModel
    }
  }
}
`;

export const SAVE_CRM = `
mutation Save(
  $crmId: String!,
  $clientName: String!,
  $buildingName: String!,
  $buildingState: String!,
  $priceDate: DateTime!,
  $startMonth: Int!,
  $startYear: Int!,
  $months: Int!,
  $status: String!,
  $craneModel: String!,
)
{
  saveCrm(
    crmId: $crmId,
    clientName: $clientName,
    buildingName: $buildingName,
    buildingState: $buildingState,
    priceDate: $priceDate,
    startMonth: $startMonth,
    startYear: $startYear,
    months: $months,
    status: $status,
    craneModel: $craneModel,
  ) {
    crm {
      id
    }
  }
}
`;

export const DELETE_CRM = `
mutation Delete($id: Int!)
{
  deleteCrm(id: $id)
  {
    id
  }
}
`;

export const ELEVATION = `
{
  elevation {
    id
    idx
    months
    startMonth
    startYear
    schedule {
      id
    }
  }
}
`;

export const SET_ELEVATION = `
mutation Set(
  $id: Int!,
  $idx: Int,
  $months: Int,
  $startMonth: Int,
  $startYear: Int,
  $schedule: Int,
)
{
  setElevation(
    id: $id,
    idx: $idx,
    months: $months,
    startMonth: $startMonth,
    startYear: $startYear,
    schedule: $schedule,
  ) {
    elevation {
      id
      idx
      months
      startMonth
      startYear
      schedule {
        id
      }
    }
  }
}
`;

export const SAVE_ELEVATION = `
mutation Save(
  $idx: Int!,
  $months: Int!,
  $startMonth: Int!,
  $startYear: Int!,
  $schedule: Int!,
)
{
  saveElevation(
    idx: $idx,
    months: $months,
    startMonth: $startMonth,
    startYear: $startYear,
    schedule: $schedule,
  ) {
    elevation {
      id
    }
  }
}
`;

export const DELETE_ELEVATION = `
mutation Delete($id: Int!)
{
  deleteElevation(id: $id)
  {
    id
  }
}
`;

export const EVALUATION_CRITERIA = `
{
  evaluationCriteria {
    id
    criteria
  }
}
`;

export const SET_EVALUATION_CRITERIA = `
mutation Set(
  $id: Int!,
  $criteria: String,
)
{
  setEvaluationCriteria(
    id: $id,
    criteria: $criteria,
  ) {
    evaluationCriteria {
      id
      criteria
    }
  }
}
`;

export const SAVE_EVALUATION_CRITERIA = `
mutation Save(
  $criteria: String!,
)
{
  saveEvaluationCriteria(
    criteria: $criteria,
  ) {
    evaluationCriteria {
      id
    }
  }
}
`;

export const DELETE_EVALUATION_CRITERIA = `
mutation Delete($id: Int!)
{
  deleteEvaluationCriteria(id: $id)
  {
    id
  }
}
`;

export const EVIDENCE = `
{
  evidence {
    id
    flutterPath
    uploaded
    evidenceFile {
      id
      url
    }
    shipping {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_EVIDENCE = `
mutation Set(
  $id: Int!,
  $evidenceFile: Int,
  $flutterPath: String,
  $uploaded: Boolean,
  $shipping: Int,
  $user: Int,
)
{
  setEvidence(
    id: $id,
    evidenceFile: $evidenceFile,
    shipping: $shipping,
    user: $user,
    flutterPath: $flutterPath,
    uploaded: $uploaded,
  ) {
    evidence {
      id
      flutterPath
      uploaded
      evidenceFile {
        id
      }
      shipping {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_EVIDENCE = `
mutation Save(
  $evidenceFile: Int!,
  $flutterPath: String!,
  $uploaded: Boolean,
  $shipping: Int!,
  $user: Int,
)
{
  saveEvidence(
    evidenceFile: $evidenceFile,
    shipping: $shipping,
    user: $user,
    flutterPath: $flutterPath,
    uploaded: $uploaded,
  ) {
    evidence {
      id
    }
  }
}
`;

export const DELETE_EVIDENCE = `
mutation Delete($id: Int!)
{
  deleteEvidence(id: $id)
  {
    id
  }
}
`;

export const INVENTORY = `
{
  inventory {
    id
    totalParts
    inUse
    category
    parts {
      id
    }
  }
}
`;

export const SET_INVENTORY = `
mutation Set(
  $id: Int!,
  $totalParts: Int,
  $inUse: Int,
  $category: String,
  $parts: [Int],
)
{
  setInventory(
    id: $id,
    totalParts: $totalParts,
    inUse: $inUse,
    category: $category,
    parts: $parts,
  ) {
    inventory {
      id
      totalParts
      inUse
      category
      parts {
        id
      }
    }
  }
}
`;

export const SAVE_INVENTORY = `
mutation Save(
  $totalParts: Int!,
  $inUse: Int!,
  $category: String!,
  $parts: [Int],
)
{
  saveInventory(
    totalParts: $totalParts,
    inUse: $inUse,
    category: $category,
    parts: $parts,
  ) {
    inventory {
      id
    }
  }
}
`;

export const DELETE_INVENTORY = `
mutation Delete($id: Int!)
{
  deleteInventory(id: $id)
  {
    id
  }
}
`;

export const INVENTORY_EXCEPTION = `
{
  inventoryException {
    id
    reason
    status
    quantity
    parts {
      id
    }
  }
}
`;

export const SET_INVENTORY_EXCEPTION = `
mutation Set(
  $id: Int!,
  $reason: String,
  $status: String,
  $quantity: Int,
  $parts: [Int],
)
{
  setInventoryException(
    id: $id,
    reason: $reason,
    status: $status,
    quantity: $quantity,
    parts: $parts,
  ) {
    inventoryException {
      id
      reason
      status
      quantity
      parts {
        id
      }
    }
  }
}
`;

export const SAVE_INVENTORY_EXCEPTION = `
mutation Save(
  $reason: String!,
  $status: String!,
  $quantity: Int!,
  $parts: [Int],
)
{
  saveInventoryException(
    reason: $reason,
    status: $status,
    quantity: $quantity,
    parts: $parts,
  ) {
    inventoryException {
      id
    }
  }
}
`;

export const DELETE_INVENTORY_EXCEPTION = `
mutation Delete($id: Int!)
{
  deleteInventoryException(id: $id)
  {
    id
  }
}
`;

export const ITEM = `
{
  item {
    id
    name
    description
    reference
    comment
    number
    quantity
    manualPage
    part {
      id
    }
  }
}
`;

export const SET_ITEM = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $reference: String,
  $comment: String,
  $number: Int,
  $quantity: String,
  $manualPage: Int,
  $part: Int,
)
{
  setItem(
    id: $id,
    name: $name,
    description: $description,
    reference: $reference,
    comment: $comment,
    number: $number,
    quantity: $quantity,
    manualPage: $manualPage,
    part: $part,
  ) {
    item {
      id
      name
      description
      reference
      comment
      number
      quantity
      manualPage
      part {
        id
      }
    }
  }
}
`;

export const SAVE_ITEM = `
mutation Save(
  $name: String!,
  $description: String,
  $reference: String,
  $comment: String,
  $number: Int!,
  $quantity: String!,
  $manualPage: Int,
  $part: Int!,
)
{
  saveItem(
    name: $name,
    description: $description,
    reference: $reference,
    comment: $comment,
    number: $number,
    quantity: $quantity,
    manualPage: $manualPage,
    part: $part,
  ) {
    item {
      id
    }
  }
}
`;

export const DELETE_ITEM = `
mutation Delete($id: Int!)
{
  deleteItem(id: $id)
  {
    id
  }
}
`;

export const MAINTENANCE = `
{
  maintenance {
    id
    status
    start
    end
    initializeDate
    type
    phase
    isVisible
    crane {
      id
    }
  }
}
`;

export const SET_MAINTENANCE = `
mutation Set(
  $id: Int!,
  $status: String,
  $start: DateTime,
  $end: DateTime,
  $initializeDate: DateTime,
  $type: String,
  $phase: Int,
  $isVisible: Boolean,
  $crane: Int,
)
{
  setMaintenance(
    id: $id,
    crane: $crane,
    status: $status,
    start: $start,
    end: $end,
    initializeDate: $initializeDate,
    type: $type,
    phase: $phase,
    isVisible: $isVisible,
  ) {
    maintenance {
      id
      status
      start
      end
      initializeDate
      type
      phase
      isVisible
      crane {
        id
      }
    }
  }
}
`;

export const SAVE_MAINTENANCE = `
mutation Save(
  $status: String!,
  $start: DateTime,
  $end: DateTime,
  $initializeDate: DateTime,
  $type: String!,
  $phase: Int!,
  $isVisible: Boolean,
  $crane: Int,
)
{
  saveMaintenance(
    crane: $crane,
    status: $status,
    start: $start,
    end: $end,
    initializeDate: $initializeDate,
    type: $type,
    phase: $phase,
    isVisible: $isVisible,
  ) {
    maintenance {
      id
    }
  }
}
`;

export const DELETE_MAINTENANCE = `
mutation Delete($id: Int!)
{
  deleteMaintenance(id: $id)
  {
    id
  }
}
`;

export const MAINTENANCE_CATEGORY = `
{
  maintenanceCategory {
    id
    name
    craneModel
    type
    phase
  }
}
`;

export const SET_MAINTENANCE_CATEGORY = `
mutation Set(
  $id: Int!,
  $name: String,
  $craneModel: String,
  $type: String,
  $phase: Int,
)
{
  setMaintenanceCategory(
    id: $id,
    name: $name,
    craneModel: $craneModel,
    type: $type,
    phase: $phase,
  ) {
    maintenanceCategory {
      id
      name
      craneModel
      type
      phase
    }
  }
}
`;

export const SAVE_MAINTENANCE_CATEGORY = `
mutation Save(
  $name: String!,
  $craneModel: String!,
  $type: String!,
  $phase: Int!,
)
{
  saveMaintenanceCategory(
    name: $name,
    craneModel: $craneModel,
    type: $type,
    phase: $phase,
  ) {
    maintenanceCategory {
      id
    }
  }
}
`;

export const DELETE_MAINTENANCE_CATEGORY = `
mutation Delete($id: Int!)
{
  deleteMaintenanceCategory(id: $id)
  {
    id
  }
}
`;

export const MAINTENANCE_EVIDENCE = `
{
  maintenanceEvidence {
    id
    skipEvidence
    value
    infoDate
    type {
      id
    }
    maintenance {
      id
    }
  }
}
`;

export const SET_MAINTENANCE_EVIDENCE = `
mutation Set(
  $id: Int!,
  $skipEvidence: Boolean,
  $value: String,
  $infoDate: DateTime,
  $type: Int,
  $maintenance: Int,
)
{
  setMaintenanceEvidence(
    id: $id,
    type: $type,
    maintenance: $maintenance,
    skipEvidence: $skipEvidence,
    value: $value,
    infoDate: $infoDate,
  ) {
    maintenanceEvidence {
      id
      skipEvidence
      value
      infoDate
      type {
        id
      }
      maintenance {
        id
      }
    }
  }
}
`;

export const SAVE_MAINTENANCE_EVIDENCE = `
mutation Save(
  $skipEvidence: Boolean!,
  $value: String,
  $infoDate: DateTime,
  $type: Int!,
  $maintenance: Int!,
)
{
  saveMaintenanceEvidence(
    type: $type,
    maintenance: $maintenance,
    skipEvidence: $skipEvidence,
    value: $value,
    infoDate: $infoDate,
  ) {
    maintenanceEvidence {
      id
    }
  }
}
`;

export const DELETE_MAINTENANCE_EVIDENCE = `
mutation Delete($id: Int!)
{
  deleteMaintenanceEvidence(id: $id)
  {
    id
  }
}
`;

export const MAINTENANCE_FILE = `
{
  maintenanceFile {
    id
    file {
      id
      url
    }
    user {
      id
    }
    maintenanceEvidence {
      id
    }
  }
}
`;

export const SET_MAINTENANCE_FILE = `
mutation Set(
  $id: Int!,
  $file: Int,
  $user: Int,
  $maintenanceEvidence: Int,
)
{
  setMaintenanceFile(
    id: $id,
    file: $file,
    user: $user,
    maintenanceEvidence: $maintenanceEvidence,
  ) {
    maintenanceFile {
      id
      file {
        id
      }
      user {
        id
      }
      maintenanceEvidence {
        id
      }
    }
  }
}
`;

export const SAVE_MAINTENANCE_FILE = `
mutation Save(
  $file: Int!,
  $user: Int,
  $maintenanceEvidence: Int!,
)
{
  saveMaintenanceFile(
    file: $file,
    user: $user,
    maintenanceEvidence: $maintenanceEvidence,
  ) {
    maintenanceFile {
      id
    }
  }
}
`;

export const DELETE_MAINTENANCE_FILE = `
mutation Delete($id: Int!)
{
  deleteMaintenanceFile(id: $id)
  {
    id
  }
}
`;

export const MAINTENANCE_PERMS = `
{
  maintenancePerms {
    id
    estimatedTime
    manuallyEnabled
    category {
      id
    }
    users {
      id
    }
    maintenance {
      id
    }
  }
}
`;

export const SET_MAINTENANCE_PERMS = `
mutation Set(
  $id: Int!,
  $estimatedTime: Float,
  $manuallyEnabled: Boolean,
  $category: Int,
  $users: [Int],
  $maintenance: Int,
)
{
  setMaintenancePerms(
    id: $id,
    category: $category,
    users: $users,
    maintenance: $maintenance,
    estimatedTime: $estimatedTime,
    manuallyEnabled: $manuallyEnabled,
  ) {
    maintenancePerms {
      id
      estimatedTime
      manuallyEnabled
      category {
        id
      }
      users {
        id
      }
      maintenance {
        id
      }
    }
  }
}
`;

export const SAVE_MAINTENANCE_PERMS = `
mutation Save(
  $estimatedTime: Float!,
  $manuallyEnabled: Boolean!,
  $category: Int!,
  $users: [Int],
  $maintenance: Int!,
)
{
  saveMaintenancePerms(
    category: $category,
    users: $users,
    maintenance: $maintenance,
    estimatedTime: $estimatedTime,
    manuallyEnabled: $manuallyEnabled,
  ) {
    maintenancePerms {
      id
    }
  }
}
`;

export const DELETE_MAINTENANCE_PERMS = `
mutation Delete($id: Int!)
{
  deleteMaintenancePerms(id: $id)
  {
    id
  }
}
`;

export const MAINTENANCE_TYPE = `
{
  maintenanceType {
    id
    name
    inputType
    category {
      id
    }
  }
}
`;

export const SET_MAINTENANCE_TYPE = `
mutation Set(
  $id: Int!,
  $name: String,
  $inputType: String,
  $category: Int,
)
{
  setMaintenanceType(
    id: $id,
    name: $name,
    category: $category,
    inputType: $inputType,
  ) {
    maintenanceType {
      id
      name
      inputType
      category {
        id
      }
    }
  }
}
`;

export const SAVE_MAINTENANCE_TYPE = `
mutation Save(
  $name: String!,
  $inputType: String!,
  $category: Int!,
)
{
  saveMaintenanceType(
    name: $name,
    category: $category,
    inputType: $inputType,
  ) {
    maintenanceType {
      id
    }
  }
}
`;

export const DELETE_MAINTENANCE_TYPE = `
mutation Delete($id: Int!)
{
  deleteMaintenanceType(id: $id)
  {
    id
  }
}
`;

export const ONEDRIVE_AUTH = `
{
  onedriveAuth {
    id
    accessToken
    refreshToken
  }
}
`;

export const SET_ONEDRIVE_AUTH = `
mutation Set(
  $id: Int!,
  $accessToken: String,
  $refreshToken: String,
)
{
  setOnedriveAuth(
    id: $id,
    accessToken: $accessToken,
    refreshToken: $refreshToken,
  ) {
    onedriveAuth {
      id
      accessToken
      refreshToken
    }
  }
}
`;

export const SAVE_ONEDRIVE_AUTH = `
mutation Save(
  $accessToken: String!,
  $refreshToken: String!,
)
{
  saveOnedriveAuth(
    accessToken: $accessToken,
    refreshToken: $refreshToken,
  ) {
    onedriveAuth {
      id
    }
  }
}
`;

export const DELETE_ONEDRIVE_AUTH = `
mutation Delete($id: Int!)
{
  deleteOnedriveAuth(id: $id)
  {
    id
  }
}
`;

export const OPERATION = `
{
  operation {
    id
    checked
    type
    itemChecked
    comment
    isSupervision
    withDesv
    contentDesviation
    isEntry
    content {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_OPERATION = `
mutation Set(
  $id: Int!,
  $checked: Boolean,
  $type: String,
  $itemChecked: String,
  $comment: String,
  $isSupervision: Boolean,
  $withDesv: Boolean,
  $contentDesviation: String,
  $isEntry: Boolean,
  $content: Int,
  $user: Int,
)
{
  setOperation(
    id: $id,
    checked: $checked,
    type: $type,
    itemChecked: $itemChecked,
    comment: $comment,
    content: $content,
    user: $user,
    isSupervision: $isSupervision,
    withDesv: $withDesv,
    contentDesviation: $contentDesviation,
    isEntry: $isEntry,
  ) {
    operation {
      id
      checked
      type
      itemChecked
      comment
      isSupervision
      withDesv
      contentDesviation
      isEntry
      content {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_OPERATION = `
mutation Save(
  $checked: Boolean!,
  $type: String!,
  $itemChecked: String!,
  $comment: String!,
  $isSupervision: Boolean!,
  $withDesv: Boolean!,
  $contentDesviation: String!,
  $isEntry: Boolean!,
  $content: Int!,
  $user: Int,
)
{
  saveOperation(
    checked: $checked,
    type: $type,
    itemChecked: $itemChecked,
    comment: $comment,
    content: $content,
    user: $user,
    isSupervision: $isSupervision,
    withDesv: $withDesv,
    contentDesviation: $contentDesviation,
    isEntry: $isEntry,
  ) {
    operation {
      id
    }
  }
}
`;

export const DELETE_OPERATION = `
mutation Delete($id: Int!)
{
  deleteOperation(id: $id)
  {
    id
  }
}
`;

export const OPERATOR_EVALUATION = `
{
  operatorEvaluation {
    id
    points
    criteria {
      id
    }
    maintenance {
      id
    }
    operator {
      id
    }
  }
}
`;

export const SET_OPERATOR_EVALUATION = `
mutation Set(
  $id: Int!,
  $points: Int,
  $criteria: Int,
  $maintenance: Int,
  $operator: Int,
)
{
  setOperatorEvaluation(
    id: $id,
    points: $points,
    criteria: $criteria,
    maintenance: $maintenance,
    operator: $operator,
  ) {
    operatorEvaluation {
      id
      points
      criteria {
        id
      }
      maintenance {
        id
      }
      operator {
        id
      }
    }
  }
}
`;

export const SAVE_OPERATOR_EVALUATION = `
mutation Save(
  $points: Int!,
  $criteria: Int!,
  $maintenance: Int!,
  $operator: Int!,
)
{
  saveOperatorEvaluation(
    points: $points,
    criteria: $criteria,
    maintenance: $maintenance,
    operator: $operator,
  ) {
    operatorEvaluation {
      id
    }
  }
}
`;

export const DELETE_OPERATOR_EVALUATION = `
mutation Delete($id: Int!)
{
  deleteOperatorEvaluation(id: $id)
  {
    id
  }
}
`;

export const ORDER = `
{
  order {
    id
    clientName
    buildingName
    buildingStreet
    buildingCity
    buildingState
    buildingAddress
    contactName
    contactPhone
    contactEmail
    feetModel
    diceSize
    requestingTrader
    comments
    modelReference
    craneVersion
    orderVersion
    configuration
    elevations
    mountingDate
    rentPeriod
    elevationsNumber
    transportNumber
    hasPowerLift
    hasCabin
    hasRadioControl
    hasCageMounting
    hasRemoteControl
    boomLength
    towerHeight
    finalHeight
    insuranceResponsable
    forwardingSystem
    status
    feetType
    baseType
    zone
    transport
    climbed
    maxClimbed
    finalCarge
    bracingNumber
    firstBracingDistance
    bracingDistance
    accessEnclosure
    heightByElevation
    jibMounting
    elevatorType
    hours
    cageType
    metadata
    applicant {
      id
    }
    crane {
      id
    }
    craneElevator {
      id
    }
  }
}
`;

export const SET_ORDER = `
mutation Set(
  $id: Int!,
  $clientName: String,
  $buildingName: String,
  $buildingStreet: String,
  $buildingCity: String,
  $buildingState: String,
  $buildingAddress: String,
  $contactName: String,
  $contactPhone: String,
  $contactEmail: String,
  $feetModel: String,
  $diceSize: String,
  $requestingTrader: String,
  $comments: String,
  $modelReference: String,
  $craneVersion: String,
  $orderVersion: String,
  $configuration: String,
  $elevations: String,
  $mountingDate: DateTime,
  $rentPeriod: Int,
  $elevationsNumber: Int,
  $transportNumber: Int,
  $hasPowerLift: Boolean,
  $hasCabin: Boolean,
  $hasRadioControl: Boolean,
  $hasCageMounting: Boolean,
  $hasRemoteControl: Boolean,
  $boomLength: Float,
  $towerHeight: Float,
  $finalHeight: Float,
  $insuranceResponsable: String,
  $forwardingSystem: String,
  $status: String,
  $feetType: String,
  $baseType: String,
  $zone: String,
  $transport: String,
  $climbed: Boolean,
  $maxClimbed: Float,
  $finalCarge: Float,
  $bracingNumber: Int,
  $firstBracingDistance: Float,
  $bracingDistance: Float,
  $accessEnclosure: Int,
  $heightByElevation: Float,
  $jibMounting: Boolean,
  $elevatorType: String,
  $hours: Int,
  $cageType: String,
  $metadata: GenericScalar,
  $applicant: Int,
  $crane: Int,
  $craneElevator: Int,
)
{
  setOrder(
    id: $id,
    clientName: $clientName,
    buildingName: $buildingName,
    buildingStreet: $buildingStreet,
    buildingCity: $buildingCity,
    buildingState: $buildingState,
    buildingAddress: $buildingAddress,
    contactName: $contactName,
    contactPhone: $contactPhone,
    contactEmail: $contactEmail,
    feetModel: $feetModel,
    diceSize: $diceSize,
    requestingTrader: $requestingTrader,
    applicant: $applicant,
    comments: $comments,
    modelReference: $modelReference,
    craneVersion: $craneVersion,
    orderVersion: $orderVersion,
    configuration: $configuration,
    elevations: $elevations,
    mountingDate: $mountingDate,
    rentPeriod: $rentPeriod,
    elevationsNumber: $elevationsNumber,
    transportNumber: $transportNumber,
    hasPowerLift: $hasPowerLift,
    hasCabin: $hasCabin,
    hasRadioControl: $hasRadioControl,
    hasCageMounting: $hasCageMounting,
    hasRemoteControl: $hasRemoteControl,
    boomLength: $boomLength,
    towerHeight: $towerHeight,
    finalHeight: $finalHeight,
    insuranceResponsable: $insuranceResponsable,
    forwardingSystem: $forwardingSystem,
    status: $status,
    feetType: $feetType,
    baseType: $baseType,
    zone: $zone,
    crane: $crane,
    transport: $transport,
    climbed: $climbed,
    maxClimbed: $maxClimbed,
    finalCarge: $finalCarge,
    craneElevator: $craneElevator,
    bracingNumber: $bracingNumber,
    firstBracingDistance: $firstBracingDistance,
    bracingDistance: $bracingDistance,
    accessEnclosure: $accessEnclosure,
    heightByElevation: $heightByElevation,
    jibMounting: $jibMounting,
    elevatorType: $elevatorType,
    hours: $hours,
    cageType: $cageType,
    metadata: $metadata,
  ) {
    order {
      id
      clientName
      buildingName
      buildingStreet
      buildingCity
      buildingState
      buildingAddress
      contactName
      contactPhone
      contactEmail
      feetModel
      diceSize
      requestingTrader
      comments
      modelReference
      craneVersion
      orderVersion
      configuration
      elevations
      mountingDate
      rentPeriod
      elevationsNumber
      transportNumber
      hasPowerLift
      hasCabin
      hasRadioControl
      hasCageMounting
      hasRemoteControl
      boomLength
      towerHeight
      finalHeight
      insuranceResponsable
      forwardingSystem
      status
      feetType
      baseType
      zone
      transport
      climbed
      maxClimbed
      finalCarge
      bracingNumber
      firstBracingDistance
      bracingDistance
      accessEnclosure
      heightByElevation
      jibMounting
      elevatorType
      hours
      cageType
      metadata
      applicant {
        id
      }
      crane {
        id
      }
      craneElevator {
        id
      }
    }
  }
}
`;

export const SAVE_ORDER = `
mutation Save(
  $clientName: String!,
  $buildingName: String!,
  $buildingStreet: String,
  $buildingCity: String,
  $buildingState: String,
  $buildingAddress: String!,
  $contactName: String!,
  $contactPhone: String!,
  $contactEmail: String!,
  $feetModel: String!,
  $diceSize: String!,
  $requestingTrader: String,
  $comments: String!,
  $modelReference: String!,
  $craneVersion: String!,
  $orderVersion: String!,
  $configuration: String!,
  $elevations: String!,
  $mountingDate: DateTime!,
  $rentPeriod: Int!,
  $elevationsNumber: Int!,
  $transportNumber: Int!,
  $hasPowerLift: Boolean!,
  $hasCabin: Boolean!,
  $hasRadioControl: Boolean!,
  $hasCageMounting: Boolean!,
  $hasRemoteControl: Boolean!,
  $boomLength: Float!,
  $towerHeight: Float!,
  $finalHeight: Float!,
  $insuranceResponsable: String,
  $forwardingSystem: String,
  $status: String!,
  $feetType: String,
  $baseType: String,
  $zone: String!,
  $transport: String,
  $climbed: Boolean!,
  $maxClimbed: Float!,
  $finalCarge: Float!,
  $bracingNumber: Int!,
  $firstBracingDistance: Float!,
  $bracingDistance: Float!,
  $accessEnclosure: Int!,
  $heightByElevation: Float!,
  $jibMounting: Boolean!,
  $elevatorType: String,
  $hours: Int!,
  $cageType: String!,
  $metadata: GenericScalar!,
  $applicant: Int,
  $crane: Int,
  $craneElevator: Int,
)
{
  saveOrder(
    clientName: $clientName,
    buildingName: $buildingName,
    buildingStreet: $buildingStreet,
    buildingCity: $buildingCity,
    buildingState: $buildingState,
    buildingAddress: $buildingAddress,
    contactName: $contactName,
    contactPhone: $contactPhone,
    contactEmail: $contactEmail,
    feetModel: $feetModel,
    diceSize: $diceSize,
    requestingTrader: $requestingTrader,
    applicant: $applicant,
    comments: $comments,
    modelReference: $modelReference,
    craneVersion: $craneVersion,
    orderVersion: $orderVersion,
    configuration: $configuration,
    elevations: $elevations,
    mountingDate: $mountingDate,
    rentPeriod: $rentPeriod,
    elevationsNumber: $elevationsNumber,
    transportNumber: $transportNumber,
    hasPowerLift: $hasPowerLift,
    hasCabin: $hasCabin,
    hasRadioControl: $hasRadioControl,
    hasCageMounting: $hasCageMounting,
    hasRemoteControl: $hasRemoteControl,
    boomLength: $boomLength,
    towerHeight: $towerHeight,
    finalHeight: $finalHeight,
    insuranceResponsable: $insuranceResponsable,
    forwardingSystem: $forwardingSystem,
    status: $status,
    feetType: $feetType,
    baseType: $baseType,
    zone: $zone,
    crane: $crane,
    transport: $transport,
    climbed: $climbed,
    maxClimbed: $maxClimbed,
    finalCarge: $finalCarge,
    craneElevator: $craneElevator,
    bracingNumber: $bracingNumber,
    firstBracingDistance: $firstBracingDistance,
    bracingDistance: $bracingDistance,
    accessEnclosure: $accessEnclosure,
    heightByElevation: $heightByElevation,
    jibMounting: $jibMounting,
    elevatorType: $elevatorType,
    hours: $hours,
    cageType: $cageType,
    metadata: $metadata,
  ) {
    order {
      id
    }
  }
}
`;

export const DELETE_ORDER = `
mutation Delete($id: Int!)
{
  deleteOrder(id: $id)
  {
    id
  }
}
`;

export const PART = `
{
  part {
    id
    craneModel
    partId
    name
    um
    isSupervision
    isManual
  }
}
`;

export const SET_PART = `
mutation Set(
  $id: Int!,
  $craneModel: String,
  $partId: String,
  $name: String,
  $um: String,
  $isSupervision: Boolean,
  $isManual: Boolean,
)
{
  setPart(
    id: $id,
    craneModel: $craneModel,
    partId: $partId,
    name: $name,
    um: $um,
    isSupervision: $isSupervision,
    isManual: $isManual,
  ) {
    part {
      id
      craneModel
      partId
      name
      um
      isSupervision
      isManual
    }
  }
}
`;

export const SAVE_PART = `
mutation Save(
  $craneModel: String!,
  $partId: String!,
  $name: String!,
  $um: String,
  $isSupervision: Boolean!,
  $isManual: Boolean!,
)
{
  savePart(
    craneModel: $craneModel,
    partId: $partId,
    name: $name,
    um: $um,
    isSupervision: $isSupervision,
    isManual: $isManual,
  ) {
    part {
      id
    }
  }
}
`;

export const DELETE_PART = `
mutation Delete($id: Int!)
{
  deletePart(id: $id)
  {
    id
  }
}
`;

export const PREVENTIVE_MAINTENANCE = `
{
  preventiveMaintenance {
    id
    status
    onedriveStatus
    zone
    maintenanceVersion
    startDate
    finishDate
    onedriveUrl
    justificaction
    sizeUpload
    order {
      id
    }
    technician {
      id
    }
    signature {
      id
      url
    }
    report {
      id
    }
    operator {
      id
    }
  }
}
`;

export const SET_PREVENTIVE_MAINTENANCE = `
mutation Set(
  $id: Int!,
  $status: String,
  $onedriveStatus: String,
  $zone: String,
  $maintenanceVersion: Int,
  $startDate: DateTime,
  $finishDate: DateTime,
  $onedriveUrl: String,
  $justificaction: String,
  $signature: Int,
  $sizeUpload: Int,
  $order: Int,
  $technician: Int,
  $report: Int,
  $operator: Int,
)
{
  setPreventiveMaintenance(
    id: $id,
    order: $order,
    technician: $technician,
    status: $status,
    onedriveStatus: $onedriveStatus,
    zone: $zone,
    maintenanceVersion: $maintenanceVersion,
    startDate: $startDate,
    finishDate: $finishDate,
    onedriveUrl: $onedriveUrl,
    justificaction: $justificaction,
    signature: $signature,
    report: $report,
    operator: $operator,
    sizeUpload: $sizeUpload,
  ) {
    preventiveMaintenance {
      id
      status
      onedriveStatus
      zone
      maintenanceVersion
      startDate
      finishDate
      onedriveUrl
      justificaction
      sizeUpload
      order {
        id
      }
      technician {
        id
      }
      signature {
        id
      }
      report {
        id
      }
      operator {
        id
      }
    }
  }
}
`;

export const SAVE_PREVENTIVE_MAINTENANCE = `
mutation Save(
  $status: String!,
  $onedriveStatus: String!,
  $zone: String!,
  $maintenanceVersion: Int,
  $startDate: DateTime!,
  $finishDate: DateTime,
  $onedriveUrl: String,
  $justificaction: String,
  $signature: Int,
  $sizeUpload: Int!,
  $order: Int,
  $technician: Int,
  $report: Int,
  $operator: Int,
)
{
  savePreventiveMaintenance(
    order: $order,
    technician: $technician,
    status: $status,
    onedriveStatus: $onedriveStatus,
    zone: $zone,
    maintenanceVersion: $maintenanceVersion,
    startDate: $startDate,
    finishDate: $finishDate,
    onedriveUrl: $onedriveUrl,
    justificaction: $justificaction,
    signature: $signature,
    report: $report,
    operator: $operator,
    sizeUpload: $sizeUpload,
  ) {
    preventiveMaintenance {
      id
    }
  }
}
`;

export const DELETE_PREVENTIVE_MAINTENANCE = `
mutation Delete($id: Int!)
{
  deletePreventiveMaintenance(id: $id)
  {
    id
  }
}
`;

export const PREVENTIVE_MAINTENANCE_CATEGORY = `
{
  preventiveMaintenanceCategory {
    id
    title
    subtitle
    craneModel
    typeQuestion
    typeEvidence
  }
}
`;

export const SET_PREVENTIVE_MAINTENANCE_CATEGORY = `
mutation Set(
  $id: Int!,
  $title: String,
  $subtitle: String,
  $craneModel: String,
  $typeQuestion: String,
  $typeEvidence: String,
)
{
  setPreventiveMaintenanceCategory(
    id: $id,
    title: $title,
    subtitle: $subtitle,
    craneModel: $craneModel,
    typeQuestion: $typeQuestion,
    typeEvidence: $typeEvidence,
  ) {
    preventiveMaintenanceCategory {
      id
      title
      subtitle
      craneModel
      typeQuestion
      typeEvidence
    }
  }
}
`;

export const SAVE_PREVENTIVE_MAINTENANCE_CATEGORY = `
mutation Save(
  $title: String!,
  $subtitle: String!,
  $craneModel: String!,
  $typeQuestion: String!,
  $typeEvidence: String!,
)
{
  savePreventiveMaintenanceCategory(
    title: $title,
    subtitle: $subtitle,
    craneModel: $craneModel,
    typeQuestion: $typeQuestion,
    typeEvidence: $typeEvidence,
  ) {
    preventiveMaintenanceCategory {
      id
    }
  }
}
`;

export const DELETE_PREVENTIVE_MAINTENANCE_CATEGORY = `
mutation Delete($id: Int!)
{
  deletePreventiveMaintenanceCategory(id: $id)
  {
    id
  }
}
`;

export const PREVENTIVE_MAINTENANCE_EVIDENCE = `
{
  preventiveMaintenanceEvidence {
    id
    value
    file {
      id
      url
    }
    category {
      id
    }
    maintenance {
      id
    }
  }
}
`;

export const SET_PREVENTIVE_MAINTENANCE_EVIDENCE = `
mutation Set(
  $id: Int!,
  $file: Int,
  $value: String,
  $category: Int,
  $maintenance: Int,
)
{
  setPreventiveMaintenanceEvidence(
    id: $id,
    file: $file,
    value: $value,
    category: $category,
    maintenance: $maintenance,
  ) {
    preventiveMaintenanceEvidence {
      id
      value
      file {
        id
      }
      category {
        id
      }
      maintenance {
        id
      }
    }
  }
}
`;

export const SAVE_PREVENTIVE_MAINTENANCE_EVIDENCE = `
mutation Save(
  $file: Int,
  $value: String,
  $category: Int!,
  $maintenance: Int!,
)
{
  savePreventiveMaintenanceEvidence(
    file: $file,
    value: $value,
    category: $category,
    maintenance: $maintenance,
  ) {
    preventiveMaintenanceEvidence {
      id
    }
  }
}
`;

export const DELETE_PREVENTIVE_MAINTENANCE_EVIDENCE = `
mutation Delete($id: Int!)
{
  deletePreventiveMaintenanceEvidence(id: $id)
  {
    id
  }
}
`;

export const REMINDER = `
{
  reminder {
    id
    status
    dateMaintenance
    order {
      id
    }
    maintenance {
      id
    }
  }
}
`;

export const SET_REMINDER = `
mutation Set(
  $id: Int!,
  $status: String,
  $dateMaintenance: DateTime,
  $order: Int,
  $maintenance: Int,
)
{
  setReminder(
    id: $id,
    status: $status,
    dateMaintenance: $dateMaintenance,
    order: $order,
    maintenance: $maintenance,
  ) {
    reminder {
      id
      status
      dateMaintenance
      order {
        id
      }
      maintenance {
        id
      }
    }
  }
}
`;

export const SAVE_REMINDER = `
mutation Save(
  $status: String!,
  $dateMaintenance: DateTime!,
  $order: Int,
  $maintenance: Int,
)
{
  saveReminder(
    status: $status,
    dateMaintenance: $dateMaintenance,
    order: $order,
    maintenance: $maintenance,
  ) {
    reminder {
      id
    }
  }
}
`;

export const DELETE_REMINDER = `
mutation Delete($id: Int!)
{
  deleteReminder(id: $id)
  {
    id
  }
}
`;

export const RESIDENT_REPORT = `
{
  residentReport {
    id
    residentName
    residentMail
    validationUrl
    supervisorUrl
    residentCheck
    supervisorCheck
    signature {
      id
      url
    }
  }
}
`;

export const SET_RESIDENT_REPORT = `
mutation Set(
  $id: Int!,
  $signature: Int,
  $residentName: String,
  $residentMail: String,
  $validationUrl: String,
  $supervisorUrl: String,
  $residentCheck: Boolean,
  $supervisorCheck: Boolean,
)
{
  setResidentReport(
    id: $id,
    signature: $signature,
    residentName: $residentName,
    residentMail: $residentMail,
    validationUrl: $validationUrl,
    supervisorUrl: $supervisorUrl,
    residentCheck: $residentCheck,
    supervisorCheck: $supervisorCheck,
  ) {
    residentReport {
      id
      residentName
      residentMail
      validationUrl
      supervisorUrl
      residentCheck
      supervisorCheck
      signature {
        id
      }
    }
  }
}
`;

export const SAVE_RESIDENT_REPORT = `
mutation Save(
  $signature: Int,
  $residentName: String,
  $residentMail: String,
  $validationUrl: String!,
  $supervisorUrl: String!,
  $residentCheck: Boolean!,
  $supervisorCheck: Boolean!,
)
{
  saveResidentReport(
    signature: $signature,
    residentName: $residentName,
    residentMail: $residentMail,
    validationUrl: $validationUrl,
    supervisorUrl: $supervisorUrl,
    residentCheck: $residentCheck,
    supervisorCheck: $supervisorCheck,
  ) {
    residentReport {
      id
    }
  }
}
`;

export const DELETE_RESIDENT_REPORT = `
mutation Delete($id: Int!)
{
  deleteResidentReport(id: $id)
  {
    id
  }
}
`;

export const SCHEDULE = `
{
  schedule {
    id
    client
    building
    state
    months
    startMonth
    startYear
    status
    crane {
      id
    }
  }
}
`;

export const SET_SCHEDULE = `
mutation Set(
  $id: Int!,
  $client: String,
  $building: String,
  $state: String,
  $months: Int,
  $startMonth: Int,
  $startYear: Int,
  $status: String,
  $crane: Int,
)
{
  setSchedule(
    id: $id,
    client: $client,
    building: $building,
    state: $state,
    months: $months,
    startMonth: $startMonth,
    startYear: $startYear,
    status: $status,
    crane: $crane,
  ) {
    schedule {
      id
      client
      building
      state
      months
      startMonth
      startYear
      status
      crane {
        id
      }
    }
  }
}
`;

export const SAVE_SCHEDULE = `
mutation Save(
  $client: String!,
  $building: String!,
  $state: String!,
  $months: Int!,
  $startMonth: Int!,
  $startYear: Int!,
  $status: String!,
  $crane: Int!,
)
{
  saveSchedule(
    client: $client,
    building: $building,
    state: $state,
    months: $months,
    startMonth: $startMonth,
    startYear: $startYear,
    status: $status,
    crane: $crane,
  ) {
    schedule {
      id
    }
  }
}
`;

export const DELETE_SCHEDULE = `
mutation Delete($id: Int!)
{
  deleteSchedule(id: $id)
  {
    id
  }
}
`;

export const SECURITY_CATEGORY = `
{
  securityCategory {
    id
    name
    partId
    craneModel
    isDeviation
    isInitial
  }
}
`;

export const SET_SECURITY_CATEGORY = `
mutation Set(
  $id: Int!,
  $name: String,
  $partId: String,
  $craneModel: String,
  $isDeviation: Boolean,
  $isInitial: Boolean,
)
{
  setSecurityCategory(
    id: $id,
    name: $name,
    partId: $partId,
    craneModel: $craneModel,
    isDeviation: $isDeviation,
    isInitial: $isInitial,
  ) {
    securityCategory {
      id
      name
      partId
      craneModel
      isDeviation
      isInitial
    }
  }
}
`;

export const SAVE_SECURITY_CATEGORY = `
mutation Save(
  $name: String!,
  $partId: String!,
  $craneModel: String!,
  $isDeviation: Boolean!,
  $isInitial: Boolean!,
)
{
  saveSecurityCategory(
    name: $name,
    partId: $partId,
    craneModel: $craneModel,
    isDeviation: $isDeviation,
    isInitial: $isInitial,
  ) {
    securityCategory {
      id
    }
  }
}
`;

export const DELETE_SECURITY_CATEGORY = `
mutation Delete($id: Int!)
{
  deleteSecurityCategory(id: $id)
  {
    id
  }
}
`;

export const SECURITY_CHECK = `
{
  securityCheck {
    id
    value
    isDeviation
    deviationValue
    findings
    corrections
    date
    scpLiberation
    securityCheckType {
      id
    }
    securityRevision {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_SECURITY_CHECK = `
mutation Set(
  $id: Int!,
  $value: String,
  $isDeviation: Boolean,
  $deviationValue: String,
  $findings: String,
  $corrections: String,
  $date: DateTime,
  $scpLiberation: String,
  $securityCheckType: Int,
  $securityRevision: Int,
  $user: Int,
)
{
  setSecurityCheck(
    id: $id,
    value: $value,
    securityCheckType: $securityCheckType,
    securityRevision: $securityRevision,
    user: $user,
    isDeviation: $isDeviation,
    deviationValue: $deviationValue,
    findings: $findings,
    corrections: $corrections,
    date: $date,
    scpLiberation: $scpLiberation,
  ) {
    securityCheck {
      id
      value
      isDeviation
      deviationValue
      findings
      corrections
      date
      scpLiberation
      securityCheckType {
        id
      }
      securityRevision {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_SECURITY_CHECK = `
mutation Save(
  $value: String!,
  $isDeviation: Boolean!,
  $deviationValue: String!,
  $findings: String!,
  $corrections: String!,
  $date: DateTime,
  $scpLiberation: String!,
  $securityCheckType: Int!,
  $securityRevision: Int!,
  $user: Int,
)
{
  saveSecurityCheck(
    value: $value,
    securityCheckType: $securityCheckType,
    securityRevision: $securityRevision,
    user: $user,
    isDeviation: $isDeviation,
    deviationValue: $deviationValue,
    findings: $findings,
    corrections: $corrections,
    date: $date,
    scpLiberation: $scpLiberation,
  ) {
    securityCheck {
      id
    }
  }
}
`;

export const DELETE_SECURITY_CHECK = `
mutation Delete($id: Int!)
{
  deleteSecurityCheck(id: $id)
  {
    id
  }
}
`;

export const SECURITY_CHECK_TYPE = `
{
  securityCheckType {
    id
    number
    name
    securityCategory {
      id
    }
  }
}
`;

export const SET_SECURITY_CHECK_TYPE = `
mutation Set(
  $id: Int!,
  $number: Float,
  $name: String,
  $securityCategory: Int,
)
{
  setSecurityCheckType(
    id: $id,
    number: $number,
    name: $name,
    securityCategory: $securityCategory,
  ) {
    securityCheckType {
      id
      number
      name
      securityCategory {
        id
      }
    }
  }
}
`;

export const SAVE_SECURITY_CHECK_TYPE = `
mutation Save(
  $number: Float!,
  $name: String!,
  $securityCategory: Int!,
)
{
  saveSecurityCheckType(
    number: $number,
    name: $name,
    securityCategory: $securityCategory,
  ) {
    securityCheckType {
      id
    }
  }
}
`;

export const DELETE_SECURITY_CHECK_TYPE = `
mutation Delete($id: Int!)
{
  deleteSecurityCheckType(id: $id)
  {
    id
  }
}
`;

export const SECURITY_REVISION = `
{
  securityRevision {
    id
    craneModel
    active
    type
    status
    initDate
    endDate
    bothId
    order {
      id
    }
    crane {
      id
    }
  }
}
`;

export const SET_SECURITY_REVISION = `
mutation Set(
  $id: Int!,
  $craneModel: String,
  $active: Boolean,
  $type: String,
  $status: String,
  $initDate: DateTime,
  $endDate: DateTime,
  $bothId: String,
  $order: Int,
  $crane: Int,
)
{
  setSecurityRevision(
    id: $id,
    craneModel: $craneModel,
    active: $active,
    order: $order,
    type: $type,
    status: $status,
    initDate: $initDate,
    endDate: $endDate,
    crane: $crane,
    bothId: $bothId,
  ) {
    securityRevision {
      id
      craneModel
      active
      type
      status
      initDate
      endDate
      bothId
      order {
        id
      }
      crane {
        id
      }
    }
  }
}
`;

export const SAVE_SECURITY_REVISION = `
mutation Save(
  $craneModel: String!,
  $active: Boolean!,
  $type: String!,
  $status: String!,
  $initDate: DateTime,
  $endDate: DateTime,
  $bothId: String!,
  $order: Int,
  $crane: Int,
)
{
  saveSecurityRevision(
    craneModel: $craneModel,
    active: $active,
    order: $order,
    type: $type,
    status: $status,
    initDate: $initDate,
    endDate: $endDate,
    crane: $crane,
    bothId: $bothId,
  ) {
    securityRevision {
      id
    }
  }
}
`;

export const DELETE_SECURITY_REVISION = `
mutation Delete($id: Int!)
{
  deleteSecurityRevision(id: $id)
  {
    id
  }
}
`;

export const SHIPPING = `
{
  shipping {
    id
    truckPlate
    transportPlate
    transportPhone
    transportOtherPhone
    transportCarrierName
    transportCompany
    deliveryDate
    status
    type
    isEnabled
    order {
      id
    }
    company {
      id
    }
  }
}
`;

export const SET_SHIPPING = `
mutation Set(
  $id: Int!,
  $truckPlate: String,
  $transportPlate: String,
  $transportPhone: String,
  $transportOtherPhone: String,
  $transportCarrierName: String,
  $transportCompany: String,
  $deliveryDate: DateTime,
  $status: String,
  $type: String,
  $isEnabled: Boolean,
  $order: Int,
  $company: Int,
)
{
  setShipping(
    id: $id,
    truckPlate: $truckPlate,
    transportPlate: $transportPlate,
    transportPhone: $transportPhone,
    transportOtherPhone: $transportOtherPhone,
    transportCarrierName: $transportCarrierName,
    transportCompany: $transportCompany,
    deliveryDate: $deliveryDate,
    status: $status,
    type: $type,
    isEnabled: $isEnabled,
    order: $order,
    company: $company,
  ) {
    shipping {
      id
      truckPlate
      transportPlate
      transportPhone
      transportOtherPhone
      transportCarrierName
      transportCompany
      deliveryDate
      status
      type
      isEnabled
      order {
        id
      }
      company {
        id
      }
    }
  }
}
`;

export const SAVE_SHIPPING = `
mutation Save(
  $truckPlate: String,
  $transportPlate: String!,
  $transportPhone: String!,
  $transportOtherPhone: String!,
  $transportCarrierName: String,
  $transportCompany: String,
  $deliveryDate: DateTime!,
  $status: String!,
  $type: String!,
  $isEnabled: Boolean!,
  $order: Int,
  $company: Int,
)
{
  saveShipping(
    truckPlate: $truckPlate,
    transportPlate: $transportPlate,
    transportPhone: $transportPhone,
    transportOtherPhone: $transportOtherPhone,
    transportCarrierName: $transportCarrierName,
    transportCompany: $transportCompany,
    deliveryDate: $deliveryDate,
    status: $status,
    type: $type,
    isEnabled: $isEnabled,
    order: $order,
    company: $company,
  ) {
    shipping {
      id
    }
  }
}
`;

export const DELETE_SHIPPING = `
mutation Delete($id: Int!)
{
  deleteShipping(id: $id)
  {
    id
  }
}
`;

export const STOCK_GROUP = `
{
  stockGroup {
    id
    name
    description
    items {
      id
    }
    crane {
      id
    }
    order {
      id
    }
  }
}
`;

export const SET_STOCK_GROUP = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $items: [Int],
  $crane: Int,
  $order: Int,
)
{
  setStockGroup(
    id: $id,
    name: $name,
    description: $description,
    items: $items,
    crane: $crane,
    order: $order,
  ) {
    stockGroup {
      id
      name
      description
      items {
        id
      }
      crane {
        id
      }
      order {
        id
      }
    }
  }
}
`;

export const SAVE_STOCK_GROUP = `
mutation Save(
  $name: String!,
  $description: String!,
  $items: [Int],
  $crane: Int,
  $order: Int,
)
{
  saveStockGroup(
    name: $name,
    description: $description,
    items: $items,
    crane: $crane,
    order: $order,
  ) {
    stockGroup {
      id
    }
  }
}
`;

export const DELETE_STOCK_GROUP = `
mutation Delete($id: Int!)
{
  deleteStockGroup(id: $id)
  {
    id
  }
}
`;

export const STOCK_ITEM = `
{
  stockItem {
    id
    item {
      id
      url
    }
  }
}
`;

export const SET_STOCK_ITEM = `
mutation Set(
  $id: Int!,
  $item: Int,
)
{
  setStockItem(
    id: $id,
    item: $item,
  ) {
    stockItem {
      id
      item {
        id
      }
    }
  }
}
`;

export const SAVE_STOCK_ITEM = `
mutation Save(
  $item: Int!,
)
{
  saveStockItem(
    item: $item,
  ) {
    stockItem {
      id
    }
  }
}
`;

export const DELETE_STOCK_ITEM = `
mutation Delete($id: Int!)
{
  deleteStockItem(id: $id)
  {
    id
  }
}
`;

export const SUGGESTION = `
{
  suggestion {
    id
    quantity
    elevationNumber
    shipping {
      id
    }
    part {
      id
    }
    order {
      id
    }
  }
}
`;

export const SET_SUGGESTION = `
mutation Set(
  $id: Int!,
  $quantity: Int,
  $elevationNumber: Int,
  $shipping: Int,
  $part: Int,
  $order: Int,
)
{
  setSuggestion(
    id: $id,
    quantity: $quantity,
    shipping: $shipping,
    part: $part,
    order: $order,
    elevationNumber: $elevationNumber,
  ) {
    suggestion {
      id
      quantity
      elevationNumber
      shipping {
        id
      }
      part {
        id
      }
      order {
        id
      }
    }
  }
}
`;

export const SAVE_SUGGESTION = `
mutation Save(
  $quantity: Int!,
  $elevationNumber: Int!,
  $shipping: Int,
  $part: Int!,
  $order: Int!,
)
{
  saveSuggestion(
    quantity: $quantity,
    shipping: $shipping,
    part: $part,
    order: $order,
    elevationNumber: $elevationNumber,
  ) {
    suggestion {
      id
    }
  }
}
`;

export const DELETE_SUGGESTION = `
mutation Delete($id: Int!)
{
  deleteSuggestion(id: $id)
  {
    id
  }
}
`;

export const TRANSPORT_COMPANY = `
{
  transportCompany {
    id
    name
    rfc
    socialReason
  }
}
`;

export const SET_TRANSPORT_COMPANY = `
mutation Set(
  $id: Int!,
  $name: String,
  $rfc: String,
  $socialReason: String,
)
{
  setTransportCompany(
    id: $id,
    name: $name,
    rfc: $rfc,
    socialReason: $socialReason,
  ) {
    transportCompany {
      id
      name
      rfc
      socialReason
    }
  }
}
`;

export const SAVE_TRANSPORT_COMPANY = `
mutation Save(
  $name: String!,
  $rfc: String!,
  $socialReason: String!,
)
{
  saveTransportCompany(
    name: $name,
    rfc: $rfc,
    socialReason: $socialReason,
  ) {
    transportCompany {
      id
    }
  }
}
`;

export const DELETE_TRANSPORT_COMPANY = `
mutation Delete($id: Int!)
{
  deleteTransportCompany(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    rol
    phone
    shippingNotification
    orderNotification
    arrisNotification
    metadata
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $rol: String,
  $phone: String,
  $shippingNotification: Boolean,
  $orderNotification: Boolean,
  $arrisNotification: Boolean,
  $metadata: GenericScalar,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    rol: $rol,
    phone: $phone,
    shippingNotification: $shippingNotification,
    orderNotification: $orderNotification,
    arrisNotification: $arrisNotification,
    metadata: $metadata,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      rol
      phone
      shippingNotification
      orderNotification
      arrisNotification
      metadata
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $rol: String!,
  $phone: String!,
  $shippingNotification: Boolean!,
  $orderNotification: Boolean!,
  $arrisNotification: Boolean!,
  $metadata: GenericScalar,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    rol: $rol,
    phone: $phone,
    shippingNotification: $shippingNotification,
    orderNotification: $orderNotification,
    arrisNotification: $arrisNotification,
    metadata: $metadata,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;