import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PreventiveMaintenancesPending = ({
    preventiveMaintenances,
    zones,
    getDaysUntilMaintenance,
    showAssignedOnly,
    setShowAssignedOnly,
    unassignUser
}) => (
    <div className="container-fluid" style={{ height: "100vh", overflow: "hidden" }}>
        <div className="row">
            <div className="col text-right">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={showAssignedOnly}
                        onChange={() => setShowAssignedOnly(!showAssignedOnly)}
                        id="assignedOnlyCheck"
                    />
                    <label className="form-check-label" htmlFor="assignedOnlyCheck">
                        Mostrar solo asignados
                    </label>
                </div>
            </div>
        </div>
        {/* Contenedor horizontal */}
        <div className="nav" style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto", height: "60vh" }}>
            {zones.map((zone) => {
                let maintenances = preventiveMaintenances
                    .filter(pm => pm.zone === zone && (!showAssignedOnly || pm.technician))
                    .sort((a, b) => getDaysUntilMaintenance(a.startDate) - getDaysUntilMaintenance(b.startDate));

                return (
                    <div key={zone} style={{ flex: "0 0 50%", height: "100%", padding: "1vh" }}>
                        <div className="card shadow" style={{ height: "100%" }}>
                            <div className="card-header font-weight-bold">
                                <h3>
                                    Zona {zone === "SHALLOWS" ? "Bajío" : zone === "CENTRE" ? "Centro" : zone === "NORTH" ? "Norte" : zone === "SOUTH" ? "Sur" : "Canadá"}
                                </h3>
                            </div>
                            <div className="card-body" style={{ padding: 0 }}>
                                <div className="row font-weight-bold mx-1" style={{ padding: "1rem" }}>
                                    <div className="col-12 col-md-3">Grúa</div>
                                    <div className="col-12 col-md-4 text-center">Obra</div>
                                    <div className="col-12 col-md-3 text-center">Días</div>
                                </div>
                                {/* Contenedor con scroll para la lista */}
                                <div style={{ maxHeight: "calc(100% - 80px)", overflowY: "auto" }}>
                                    {maintenances.length > 0 ? (
                                        maintenances.map((maintenance, idx) => {
                                            const daysUntilMaintenance = getDaysUntilMaintenance(maintenance.startDate);
                                            const daysStyle = { color: daysUntilMaintenance <= 0 ? "red" : "#FFAD01" };
                                            return (
                                                <div
                                                    className="row border mx-1 text-reset"
                                                    key={maintenance.id}
                                                    style={{
                                                        background: idx % 2 === 0 ? "#fff" : "#f5f5f5",
                                                        padding: "0.5rem"
                                                    }}
                                                >
                                                    <div className="col-12 col-md-3">
                                                        {maintenance.order.crane.series +
                                                            " / " +
                                                            maintenance.order.crane.model +
                                                            maintenance.order.crane.number}
                                                    </div>
                                                    <div className="col-12 col-md-4 text-center">
                                                        {maintenance.order.buildingName}
                                                    </div>
                                                    <div className="col-12 col-md-3 text-center" style={daysStyle}>
                                                        {daysUntilMaintenance}
                                                    </div>
                                                    <div className="col-12 col-md-2 text-center">
                                                        {maintenance.technician ? (
                                                            <>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <Link
                                                                        to={{ pathname: "/reassign/" + maintenance.id }}
                                                                        className="btn btn-secondary"
                                                                        title="Reasignar"
                                                                    >
                                                                        <i className="fa fa-user-edit"></i>
                                                                    </Link>
                                                                    <button
                                                                        className="btn btn-danger ml-1"
                                                                        onClick={() => unassignUser(maintenance.id)}
                                                                        title="Desasignar"
                                                                    >
                                                                        <i className="fa fa-user-times"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="mt-1">
                                                                    <h6>
                                                                        Asignado a:
                                                                        <span className="text-primary">
                                                                            {" " +
                                                                                maintenance.technician.firstName +
                                                                                " " +
                                                                                maintenance.technician.lastName}
                                                                        </span>
                                                                    </h6>
                                                                </div>
                                                            </>
                                                        ) : maintenance.status === "CREATED" ? (
                                                            <Link
                                                                to={{ pathname: "/assign/" + maintenance.id }}
                                                                className="btn btn-primary"
                                                            >
                                                                Asignar
                                                            </Link>
                                                        ) : maintenance.status === "IN_PROGRESS" ? (
                                                            <div
                                                                className="btn btn-danger"
                                                                style={{ cursor: "not-allowed" }}
                                                            >
                                                                En proceso
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="row border mx-1 text-reset" style={{ background: "#fff", padding: "1rem" }}>
                                            <div className="col text-center">
                                                No hay mantenimientos pendientes para esta zona.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    </div>
);

PreventiveMaintenancesPending.propTypes = {
    preventiveMaintenances: PropTypes.array,
    zones: PropTypes.array.isRequired,
    getDaysUntilMaintenance: PropTypes.func,
    showAssignedOnly: PropTypes.bool,
    setShowAssignedOnly: PropTypes.func,
    handleUnassign: PropTypes.func.isRequired
};

export default PreventiveMaintenancesPending;
