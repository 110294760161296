import React from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import css from "styles/css/Navbar.css";

const Sidenav = ({ rol, path = "" }) =>
  <aside class={`js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical 
  navbar-vertical-fixed navbar-expand-xl navbar-bordered navbar-dark avbar-vertical-aside-initialized`}>

    <div class="navbar-vertical-container">
      <div class="navbar-vertical-footer-offset">

        <div class="navbar-brand-wrapper justify-content-between pt-2" style={{ height: "7rem" }}>

          {/* Logo */}
          <a class="" href="./index.html" aria-label="Front">
            <div className="d-flex align-items-center">
              <img class="z-index-2" src="/theme/img/groke-white.png" alt="Logo" style={{ width: "10rem" }} />
            </div>
          </a>

          {/* Vertical Toggle */}
          <button type="button" class={`js-navbar-vertical-aside-toggle-invoker
          navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark`}>
            <i class="tio-clear tio-lg"></i>
          </button>

        </div>

        {/* Content */}
        <div class="navbar-vertical-content">
          <ul class="navbar-nav navbar-nav-lg nav-tabs">

            {/* Cranes */}
            {rol.startsWith("ADMIN") ?
              <li class="navbar-item">
                <NavLink
                  to="/cranes"
                  className="js-nav-tooltip-link nav-link"
                  activeClassName="active">
                  <i class="fa fa-anchor nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                    Grúas
                  </span>
                </NavLink>
              </li>
              : null
            }

            {/* Orders */}
            {rol.startsWith("ADMIN") || rol == "COMMERCIAL" || rol == "SECURITY"
              ? <li class="navbar-item">
                <NavLink
                  to="/orders"
                  className="js-nav-tooltip-link nav-link"
                  activeClassName="active">
                  <i class="fa fa-map nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                    Órdenes
                  </span>
                </NavLink>
              </li>
              : null
            }

            {/* Shippings */}
            {rol.startsWith("ADMIN") || rol == "SECURITY" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Embarques">
                  <i class="fa fa-box nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Embarques</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/shippings/entries" ? "active" : ""}`}
                      to="/shippings/entries">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Entradas</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/shippings/departures" ? "active" : ""}`}
                      to="/shippings/departures">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Salidas</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/shippings/enabled" ? "active" : ""}`}
                      to="/shippings/enabled">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Habilitados</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/shippings" ? "active" : ""}`}
                      to="/shippings">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">General</span>
                    </Link>
                  </li>
                </ul>
                
              </li> : null
            }

            {/* Inventory */}
            {rol.startsWith("ADMIN") || rol == "SECURITY" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Inventario">
                  <i class="fa fa-boxes nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Inventario</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/inventory" ? "active" : ""}`}
                      to="/inventory">
                      <span class="tio-circle
                      nav-indicator-icon"></span>
                      <span class="text-truncate">Reporte general</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/inventory/building" ? "active" : ""}`}
                      to="/inventory/building">
                      <span class="tio-circle
                      nav-indicator-icon"></span>
                      <span class="text-truncate">Reporte por obra</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/inventory/exceptions" ? "active" : ""}`}
                      to="/inventory/exceptions">
                      <span class="tio-circle
                      nav-indicator-icon"></span>
                      <span class="text-truncate">Excepciones</span>
                    </Link>
                  </li>
                </ul>
              </li> : null
            }


            {rol == "ADMIN_COMMERCIAL" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Comercial">
                  <i class="fa fa-money-check nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">UT</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/commercial/cranes" ? "active" : ""}`} to="/commercial/cranes">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Gestión de grúas</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class={`nav-link ${path == "/commercial/schedule" ? "active" : ""}`}
                      to="/commercial/schedule"
                    >
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Cronograma logístico</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/commercial/crm" ? "active" : ""}`}
                      to="/commercial/crm">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Módulo CRM</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/commercial/analytics" ? "active" : ""}`}
                      to="/commercial/analytics">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Analítica</span>
                    </Link>
                  </li>
                </ul>
              </li> : null
            }

            {rol == "ADMIN_COMMERCIAL" || rol.startsWith("ADMIN") || rol == "SECURITY" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Comercial">
                  <i class="fa fa-list nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Bitácora comercial</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/arris_binnacle/weekly_report" ? "active" : ""}`}
                      to="/arris_binnacle/weekly_report">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Reporte semanal</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/arris_binnacle/monthly_report" ? "active" : ""}`}
                      to="/arris_binnacle/monthly_report">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Reporte mensual</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/commercial/analytics" ? "active" : ""}`}
                      to="/arris_binnacle/analytics_client">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Analítica por cliente</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/commercial/analytics" ? "active" : ""}`}
                      to="/arris_binnacle/analytics_crane">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Analítica por grúa</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/commercial/analytics" ? "active" : ""}`}
                      to="/arris_binnacle/analytics_order">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Analítica por orden</span>
                    </Link>
                  </li>

                </ul>
              </li> : null
            }

            {rol != "MAINTENANCE_SUPERVISOR" ?
              <div class="dropdown-divider my-3" style={{ borderTopColor: "#ffffff20" }}></div>
            : null}

            {/* ARISS */}
            {
              rol.startsWith("ADMIN") || rol == "SECURITY"
                ? <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                  <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Apps">
                    <i class="fa fa-cube nav-icon"></i>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">ARISS</span>
                  </a>

                  <ul
                    class="js-navbar-vertical-aside-submenu nav nav-sub"
                    style={{ display: "none", top: "195.450px" }}
                  >
                    <li class="nav-item">
                      <Link class={`nav-link ${path == "/ariss" ? "active" : ""}`} to="/ariss">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span class="text-truncate">Asignación Ariss</span>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        class={`nav-link ${path == "/ariss/weekly_report" ? "active" : ""}`}
                        to="/ariss/weekly_report"
                      >
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span class="text-truncate">Reporte semanal </span>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class={`nav-link ${path == "/ariss/failure_report" ? "active" : ""}`}
                        to="/ariss/failure_report">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span class="text-truncate">Reportes con fallas</span>
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link class={`nav-link ${path == "/ariss/not_sent_report" ? "active" : ""}`}
                        to="/ariss/not_sent_report">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span class="text-truncate">Reportes no enviados</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                : null
            }

            {
              rol.startsWith("ADMIN") || rol == "MAINTENANCE_SUPERVISOR"
                ? <div class="dropdown-divider my-3" style={{ borderTopColor: "#ffffff20" }}></div>
                : null
            }

            {(rol === "ADMIN_COMMERCIAL" || rol === "MAINTENANCE_SUPERVISOR") && (
              <li
                className="navbar-item navbar-vertical-aside-has-menu"
                style={{ cursor: "pointer" }}
              >
                <a
                  className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle"
                  title="Analítica de datos"
                >
                  <i className="fa fa-chart-bar nav-icon"></i>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                    Analítica de datos
                  </span>
                </a>
                {(rol === "ADMIN_COMMERCIAL" || rol === "MAINTENANCE_SUPERVISOR") && (
                  <ul
                    className="js-navbar-vertical-aside-submenu nav nav-sub ml-3"
                    style={{ display: "none", top: "195.450px" }}
                  >
                    <li className="nav-item ml-2">
                      <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                        Mantenimiento
                      </span>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${path === "/analytics/general_report" ? "active" : ""}`}
                        to="/analytics/general_report"
                      >
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Reporte general</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${path === "/analytics/crane_report" ? "active" : ""}`}
                        to="/analytics/crane_report"
                      >
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Reporte por grúa</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${path === "/analytics/performance_report" ? "active" : ""}`}
                        to="/analytics/performance_report"
                      >
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Reporte de desempeño</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            {/* Maintenance */}
            {
              rol.startsWith("ADMIN") || rol == "MAINTENANCE_SUPERVISOR"
                ? <li class="navbar-item">
                    <NavLink
                      to="/maintenances"
                      className="js-nav-tooltip-link nav-link"
                      activeClassName="active">
                      <i class="fa fa-hammer nav-icon"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                        Mantenimientos
                      </span>
                    </NavLink>
                  </li>
                : null
            }

            {rol == "ADMIN_COMMERCIAL" || rol.startsWith("ADMIN") || rol == "MAINTENANCE_SUPERVISOR" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Preventivos">
                  <i class="fa fa-tools nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Mantenimientos preventivos</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/preventive_maintenance/done" ? "active" : ""}`}
                      to="/preventive_maintenance/done">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Realizados</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/preventive_maintenance/pending" ? "active" : ""}`}
                      to="/preventive_maintenance/pending">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Por realizar</span>
                    </Link>
                  </li>
                </ul>
              </li> : rol == "SECURITY" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Preventivos">
                  <i class="fa fa-tools nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Mantenimientos preventivos</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/preventive_maintenance/done" ? "active" : ""}`}
                      to="/preventive_maintenance/done">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Realizados</span>
                    </Link>
                  </li>
                </ul>
              </li> : null
            }

            <div class="dropdown-divider my-3" style={{ borderTopColor: "#ffffff20" }}></div>

            {/* Security */}
            {rol.startsWith("ADMIN") || rol == "SECURITY" ?
              <li class="navbar-item navbar-vertical-aside-has-menu" style={{ cursor: "pointer" }}>
                <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " title="Calidad">
                  <i class="fa fa-list nav-icon"></i>
                  <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Calidad</span>
                </a>

                <ul
                  class="js-navbar-vertical-aside-submenu nav nav-sub"
                  style={{ display: "none", top: "195.450px" }}
                >
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/security_revisions" ? "active" : ""}`}
                      to="/security_revisions">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Revisiones</span>
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class={`nav-link ${path == "/security_stock" ? "active" : ""}`}
                      to="/security_stock">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span class="text-truncate">Inventario</span>
                    </Link>
                  </li>
                </ul>
              </li> : null
            }

            {/* Binnacle */}
            {
              rol.startsWith("ADMIN")
                ? <li class="navbar-item">
                  <NavLink
                    to="/binnacles"
                    className="js-nav-tooltip-link nav-link"
                    activeClassName="active">
                    <i class="fa fa-clipboard nav-icon"></i>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Bitácora
                    </span>
                  </NavLink>
                </li>
                : null
            }

            {
              rol.startsWith("ADMIN") || rol == "SECURITY" || rol == "MAINTENANCE_SUPERVISOR"
                ? <li class="navbar-item">
                  <NavLink
                    to="/ariss/failures_report"
                    className="js-nav-tooltip-link nav-link"
                    activeClassName="active"
                  >
                    <i class="fa fa-fire nav-icon"></i>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Control de fallas
                    </span>
                  </NavLink>
                </li>
                : null
            }

            {
              rol.startsWith("ADMIN") || rol == "SECURITY"
                ? <div class="dropdown-divider my-3" style={{ borderTopColor: "#ffffff20" }}></div>
                : null
            }

            {/* Users */}
            {
              rol.startsWith("ADMIN") || rol == "SECURITY" || rol == "MAINTENANCE_SUPERVISOR"
                ? <li class="navbar-item">
                  <NavLink
                    to="/users"
                    className="js-nav-tooltip-link nav-link"
                    activeClassName="active"
                  >
                    <i class="fa fa-user nav-icon"></i>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      Usuarios
                    </span>
                  </NavLink>
                </li>
                : null
            }

            {
              rol.startsWith("ADMIN") || rol == "SECURITY" || rol == "MAINTENANCE_SUPERVISOR"
                ? <div class="dropdown-divider my-3" style={{ borderTopColor: "#ffffff20" }}></div>
                : null
            }

            {/* Help files */}
            <li class="navbar-item">
              <NavLink
                to="/help"
                className="js-nav-tooltip-link nav-link"
                activeClassName="active"
              >
                <i class="fa fa-info nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                  Ayuda
                </span>
              </NavLink>
            </li>

            {/* Manuals files */}
            <li class="navbar-item">
              <NavLink
                to="/manuals"
                className="js-nav-tooltip-link nav-link"
                activeClassName="active"
              >
                <i class="far fa-file-alt nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                  Manuales
                </span>
              </NavLink>
            </li>

            {/* Sign out */}
            <li class="navbar-item">
              <NavLink
                to="/logout"
                className="js-nav-tooltip-link nav-link"
                activeClassName="active"
              >
                <i class="tio-sign-out nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                  Cerrar sesión
                </span>
              </NavLink>
            </li>

          </ul>
        </div>

      </div>
    </div>

  </aside >;

Sidenav.propTypes = {
  rol: PropTypes.string.isRequired,
  path: PropTypes.string
};

export default Sidenav;
