import React, { useState } from "react";
import PropTypes from "prop-types";
import View from "components/preventive_maintenances/pending/List.view";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import Error from "components/helpers/Error";
import { formatFilters, formatSearchFilters } from "components/utils/filters";
import dayjs from 'dayjs';
import swal from "sweetalert";

const PreventiveMaintenancesPending = ({ search }) => {

    const [showAssignedOnly, setShowAssignedOnly] = useState(false);
    const zones = ["NORTH", "SOUTH", "SHALLOWS", "CENTRE", "CANADA"];

    const [unassignTechnician, setUnassignTechnician] = usePost("/preventive_maintenances/unassign_technician", {
        onCompleted: (data) => {
            if(data.value_response == -1){
                swal("Error", "No hay un operador asignado a la orden.", "error").then(() => {
                    window.location.reload();
                });
            }else if(data.value_response == 1){
                swal("¡Éxito!", "Técnico desasignado correctamente.", "success").then(() => {
                    window.location.reload();
                });
            }
        },
        onError: () => {
            swal("Error", "Error al desasignar el técnico", "error");
        }
    });

    let filters = formatFilters([
        formatSearchFilters(search, ["order.crane.series", "order.crane.model", "order.crane.number", "order.buildingName", "technician.firstName"])
    ]);

    filters = "(" + filters + ") AND status<>APPROVED AND status<>FINISHED";

    const qPreventiveMaintenances = useQuery(`
    {
        preventiveMaintenances {
            status
            zone
            startDate
            order{
                buildingName
                crane{
                    model
                    series
                    number
                }
            }
            technician{
                firstName
                lastName
            } 
        }
    }`, 
    filters);

    if (qPreventiveMaintenances.loading) return <Loading />;
    if (qPreventiveMaintenances.error) return <Error/>;

    const { preventiveMaintenances } = qPreventiveMaintenances.data;

    const getDaysUntilMaintenance = (startDate) => {
        const today = dayjs().startOf('day');
        const start = dayjs(startDate).startOf('day');
        const diff = start.diff(today, 'day');
        return diff;
    };

    const unassignUser = (maintenance_id) => { 
        swal({
            title: "¿Estás seguro?",
            text: "Esta acción eliminará el inventario y todas las piezas asociadas.",
            icon: "warning",
            buttons: ["Cancelar", "Eliminar"],
            dangerMode: true,
        }).then((willDesassign) => {
            if (willDesassign) {
                unassignTechnician({ maintenance_id: maintenance_id });
            }
        });
    }

    return <View 
            getDaysUntilMaintenance={getDaysUntilMaintenance}
            preventiveMaintenances={preventiveMaintenances}
            setShowAssignedOnly={setShowAssignedOnly}
            showAssignedOnly={showAssignedOnly} 
            unassignUser={unassignUser}
            zones={zones}  />;
            
}

PreventiveMaintenancesPending.propTypes = {
    orderDate: PropTypes.string,
    pathName: PropTypes.string
};

export default PreventiveMaintenancesPending;